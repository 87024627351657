<template>

  <div>
    <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" @refetch-data="refetchData" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('Show') }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" :placeholder="$t('Search...')" />
              <b-button variant="primary" @click="newCategory()">
                <span class="text-nowrap">{{ $t('Add Department') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refUserListTable" class="position-relative" :filter="searchQuery" :items="departments" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
        :empty-text="$t('No matching records found')" :sort-desc.sync="isSortDirDesc" :per-page="perPage"
        @filtered="onFiltered" :current-page="currentPage">

        <template #head()="data">
          <span class="text">{{ $t(data.label.toUpperCase()) }}</span>
        </template>
        <template #cell(upperCategory)="data">
          <div class="text-nowrap">
            <span v-if="data.item && data.item.Parent" class="align-text-top text-capitalize">
              {{ data.item.Parent.name }}
            </span>
          </div>
        </template>

        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveUserStatusVariant(data.item.status)}`" class="text-capitalize">
            {{ resolveUserStatusVariantText(data.item.status) }}

          </b-badge>
        </template>
        <template #cell(isImported)="data">
          <div class="text-nowrap">

            <b-badge pill v-if="data.item.isImported" variant="light-success" class="text-capitalize">
              {{ $t('IMPORTED') }}

            </b-badge>
            <b-badge pill v-if="!data.item.isImported" variant="light-secondary" class="text-capitalize">
              {{ moment(data.item.createdAt).format('Do MMMM YYYY, hh:MM ') }}

            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <b-button variant=".btn-flat-dark" @click="changeCategory(data.item)" v-if="!data.item.isImported">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $t("Edit") }}</span>
              </b-button>
            </template>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{
              $t('of') }}
              {{ dataMeta.of }}
              {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="perPage" first-number last-number
              aria-controls="my-table" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './DepartmentsListFilters.vue'
import useUsersList from './useDepartmentsList'
import userStoreModule from '../departmentStoreModule'
import UserListAddNew from './DepartmentListAddNew.vue'
import moment from 'moment'

export default {

  computed: {

  },
  methods: {
    onFiltered(filteredItems) {
      this.totalUsers = filteredItems.length
      this.currentPage = 1
    },
    startMigrating() {
      this.migrationStarted = !this.migrationStarted;
    },
    changeCategory(e) {
      this.selectedCategory = e;
      this.isAddNewUserSidebarActive = true;
    },

    deletePosition(e) {
      this.selectedCategory = e;
      this.isAddNewUserSidebarActive = true;
    },
    newCategory() {
      this.selectedCategory = {
        name: null,
        description: null,
        upperCategory: null,
        Parent: null,

      };
      this.isAddNewUserSidebarActive = true;

    },
    moment: function () {
      return moment();
    }
  },
  provide() {
    const categorydata = {}

    Object.defineProperty(categorydata, "selectedCategory", {
      enumerable: true,
      get: () => this.selectedCategory,
    })

    return {

      categorydata,
    }
  },
  components: {
    UsersListFilters,
    UserListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      migrationStarted: false,
      selectedCategory: null,
    }
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'apps-departments'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    const {
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      departments,
      isSortDirDesc,
      refUserListTable,
      refetchData,
    } = useUsersList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      departments,
      fetchInstructers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
